.matchContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.matchText {
  display: flex;
  /* font-family: 'Averta-Regular'; */
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 1.32em;
  color: #210438;
  text-align: left;
  opacity: 0.7;
  margin-top: calc(10vh + 20px);
}

.goOutText {
  /* font-family: 'Averta-Regular'; */
  font-family: 'Open Sans';
  font-weight: 400;
  color: #4C41D4;
  text-decoration: underline;
  font-size: 0.97em;
  margin-top: calc(3vh + 8px);
  padding-bottom: calc(3vh + 16px);
}

@media screen and (max-width: 760px) {

  .matchText {
    text-align: center;
    padding-left: calc(3vw + 6px);
    padding-right: calc(3vw + 6px);
  }

}
