.logoContainer {
  width: 128px;
  padding-top: 36px;
  padding-left: 0;
  align-self: center;
}

.mainText {
  padding-left: calc(4.2vw + 12px);
  padding-right: calc(4.2vw + 12px);
  color: white;
  font-size: 1.8em;
  text-align: center;
  margin: 0 0 24px 0;
  margin-top: 40px;
  margin-bottom: 26px;
  /* background-color: red; */
}

.descriptionText {
  color: rgb(250,250,250);
  font-size: 1.2em;
  line-height: 1.3;
  text-align: justify;
  margin: 0;
  padding-left: calc(4.2vw + 12px);
  padding-right: calc(4.2vw + 12px);
  /* background-color: red; */
}

.semibold {
  /* font-family: 'Averta Semibold'; */
  /* font-family: 'Averta'; */
  /* font-weight: 600; */
  font-family: 'Open Sans';
  font-weight: 600;
  /* font-family: 'Averta-Semibold'; */

}
.regular {
  /* font-family: 'Averta';
  font-weight: 600; */
  /* font-family: 'Averta-Regular'; */
  font-family: 'Open Sans';
  font-weight: 400;
  /* font-family: 'Averta Regular'; */
}

.light {
  /* font-family: 'Averta Light'; */
  /* font-family: 'Averta'; */
  /* font-weight: lighter; */
  /* font-weight: 400; */
  font-family: 'Open Sans';
  font-weight: 300;
  /* font-family: 'Averta-Light'; */
}

.backgroudImage {
  display: flex;
  flex-direction: column;
  background-image:
    linear-gradient(
      /* rgba(166,73,242,0.91),
      rgba(166,73,242,0.91) */
      rgba(184,63,235,0.91),
      rgba(184,63,235,0.91)
    ),
    url(../assets/images/smallImage.jpg);

  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 0% 0%; */
  background-position: center;
  /* background-color: '#a649f2'; */
  /* width: 100%;
  height: 50vh;
  background-repeat: no-repeat; */
  /* background-size: cover; */
  /* object-fit: cover; */
  /* overflow: hidden; */

}

.mainContainer {
  display: flex;
  flex-direction: column;
}

.leftContainer {
  display: flex;
  flex: 1.3;
  /* background-color: green; */

  flex-direction: column;
  justify-content: flex-end;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.rightImage {
  /* display: flex; */
  /* flex: 1; */
  /* width: 40vh; */
  height: 80vh;
  margin-top: calc(3.7vh + 18px);
  padding-left: calc(4.2vw + 12px);
  padding-right: calc(4.2vw + 12px);
}

.fadeContainer {
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  width: 50vh;
}

.storeIcons {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: flex-end;
  margin-top: 36px;
  margin-bottom: 36px;
  padding-left: 0;
  padding-right: 0;
}

.storeIcon {
  width: calc(2vw + 130px);
}

.storeIconRightMargin {
  margin-right: calc(1.9vw + 14px);
}

.socialIcons {
  margin: 0;
  padding: 0;
  display: flex;
  align-self: center;
  padding-bottom: 12px;
  /* background-color: purple; */
}

.socialIcon {
  width: calc(0.66vw + 22px);
}

.socialIconRightMargin {
  margin-right: calc(0.4vw + 8px);
}

@media screen and (min-width: 760px) {

  .logoContainer {
    align-self: flex-start;
    padding-left: calc(4.2vw + 12px);
    /* padding-right: calc(6.5% + 15px); */
    /* padding-top: 32px; */
  }

  .descriptionText {
    text-align: left;
    font-size: 1.4em;
  }

  .backgroudImage {
    height: 100vh;
    background-image: linear-gradient(
      rgba(184,63,235,0.91),
      rgba(184,63,235,0.91)
    ),
    url(../assets/images/mainImage.jpeg);
    background-position: center;
  }

  .mainContainer {
    flex-direction: row;
  }

  .mainText {
    font-size: 2.6em;
    margin-top: 0;
    text-align: left;
  }

  .rightImage {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .storeIcons {
    align-self: flex-start;
    padding-left: calc(4.2vw + 12px);
    margin-bottom: 24px;
  }

  .socialIcons {
    display: flex;
    align-self: center;
  }

  .rightContainer {
    justify-content: center;
    margin-bottom: 0;
  }

}
